import Button from '@algbra/ui/components/Button/Button'
import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Input from '@algbra/ui/components/Input/Input'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Select from '@algbra/ui/components/Select/Select'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { yupResolver } from '@hookform/resolvers/yup'
import { WindowLocation } from '@reach/router'
import axios from 'axios'
import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby-link'
import { filter, map, sort } from 'rambdax'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import Lottie from 'react-lottie'
import * as yup from 'yup'
import Config from '../../config'
import * as animationData from '../InlineEarlyAccess/alerts-success.json'
import styles from '../InlineEarlyAccess/InlineEarlyAccess.module.scss'

const QUERY_ROADSHOW_CITIES = graphql`
  query RoadshowCities {
    strapiGQL {
      roadshowCities {
        name
        date
        visible
      }
      waitlistBanner {
        title
        subtitle
        id
      }
    }
  }
`
export type City = {
  name: string
  date: string
  visible: boolean
}
export type ZoneInlineEarlyAccessRoadshowProps = {
  __typename: 'StrapiGQL_ComponentZonesInlineEarlyAccessRoadshow'
  id: string
  title: string
  location?: WindowLocation<{ value: string; cityName?: string }>
}
const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}
export const schema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  cityName: yup.string().required().label('City'),
  email: yup.string().email().required().label('E-mail'),
})

export default function ZoneInlineEarlyAccessRoadshow(
  props: ZoneInlineEarlyAccessRoadshowProps
): JSX.Element {
  const { location } = props
  const {
    strapiGQL: { roadshowCities, waitlistBanner },
  } = useStaticQuery(QUERY_ROADSHOW_CITIES)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
    defaultValues: {
      firstName: '',
      lastName: '',
      cityName: '',
      email: location?.state?.value || '',
    },
  })

  useEffect(() => {
    if (location && location.state && location.state.cityName) {
      setValue('cityName', location.state.cityName)
    }
    //eslint-disable-next-line
  }, [location?.state?.cityName])

  const onFormValid = async values => {
    const token = await recaptchaRef.current.executeAsync()
    setLoading(true)
    axios
      .post('/.netlify/functions/subscribe', {
        ...getValues(),
        token,
        roadshow: 1,
      })
      .then(() => {
        setLoading(false)
        setSubmitted(true)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'gtm_step2_sign_up',
        })
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('#successful-registration')
      })
      .catch(e => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          error: e.response?.data?.error,
          event: 'subscribe_error',
        })
        setLoading(false)
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('#failed-registration')
        window.alert(
          'There was an error trying to send your request. Please try again later.'
        )
      })
  }
  const cityOptions = map(
    city => ({
      label: city.name,
      value: city.name,
    }),
    sort(
      (x, y) => (dayjs(x.date).unix() > dayjs(y.date).unix() ? 1 : -1),
      filter(city => city.visible, roadshowCities || []) || []
    ) as City[]
  )
  return (
    <Whitespace type="none">
      <Container className={styles.roadshow}>
        <div className={styles.header}>
          {waitlistBanner.subtitle && (
            <p className={styles.text}>{waitlistBanner.subtitle}</p>
          )}
          <Heading level={2} type="xl" className={styles.title}>
            {waitlistBanner.title}
          </Heading>
        </div>
        <div className={styles.container}>
          {!isSubmitted && (
            <form id="newsletter-roadshow-page">
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={Config.reCaptchaKey}
              />
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-003-edit-firstname-field"
                    placeholder="First Name"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-003-edit-lastname-field"
                    placeholder="Last Name"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="cityName"
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="ID-003-edit-cityname-field"
                    controlClassName={styles.input}
                    placeholder="City"
                    className={styles.inputContainer}
                    items={cityOptions}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-004-edit-email-field"
                    placeholder="E-mail"
                    type="email"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />
              <FreeText className={styles.helpText} type="sm">
                By entering your email, you agree to our{' '}
                <Permalink
                  to="/privacy-policy"
                  isBlank={true}
                  isExternal={true}
                >
                  Privacy Policy
                </Permalink>
              </FreeText>
              <Button
                onClick={handleSubmit(onFormValid)}
                id="ID-005-submit-button"
                theme="primary"
                loading={isLoading}
                className={styles.submit}
              >
                Sign up
              </Button>
            </form>
          )}
          {isSubmitted && (
            <div
              className={styles.submitted}
              id="newsletter-roadshow-page--success"
            >
              <Lottie options={lottieOptions} height={60} width={60} />
              <Heading level={3} type="sm" className={styles.title}>
                You're on the waiting list!
              </Heading>
              <Caption type="md" className={styles.description}>
                Thank you for joining the waiting list.
                <br />
                Keep an eye on your inbox for the latest updates from Algbra!
              </Caption>
            </div>
          )}
        </div>
      </Container>
    </Whitespace>
  )
}
