import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { nl2br } from '@algbra/ui/text'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { graphql, navigate } from 'gatsby'
import { filter, map, sort } from 'rambdax'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import ContentBox from '../components/ContentBox/ContentBox'
import MetaTags from '../components/MetaTags/MetaTags'
import PageLayout from '../components/PageLayout/PageLayout'
import { getMetaTags } from '../utils/metaTags'
import ZoneInlineEarlyAccessRoadshow, {
  City,
} from '../zones/InlineEarlyAccessRoadshow/InlineEarlyAccessRoadshow'
import styles from './roadshow.module.scss'

export default function LayoutRoadshow({ pageContext, location, data }) {
  const roadshow = data.strapiGQL.roadshow
  const cities: City[] = sort(
    (x, y) => (dayjs(x.date).unix() > dayjs(y.date).unix() ? 1 : -1),
    filter(city => city.visible, roadshow.roadshow_cities || []) || []
  )
  const { ref, inView } = useInView()
  const getEventCalendar = (date: string, city: string) => {
    return `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https%3A%2F%2Falgbra.com/roadshow%2F%0PRODID:0001%0ADTSTART:${dayjs(
      date
    ).format('YYYYMMDDTHHmmss')}Z%0ADTEND:${dayjs(date)
      .add(2, 'h')
      .add(30, 'm')
      .format(
        'YYYYMMDDTHHmmss'
      )}Z%0ASUMMARY:Algbra%20Roadshow%20-%20${city}%0AEND:VEVENT%0AEND:VCALENDAR%0A`
  }
  const onEventClick = (cityName: string) => {
    navigate('#roadshow-signup', { state: { cityName } })
  }
  return (
    <PageLayout theme="roadShow">
      <MetaTags {...getMetaTags(pageContext)} />
      <Container>
        <Whitespace type="xl" hasAnimation={false}>
          <header className={styles.header}>
            <img
              src="/images/logo-on-pink.svg"
              alt=""
              width={140}
              className={styles.logo}
            />
            <Heading level={1} type="xxl" className={classNames(styles.title)}>
              {pageContext.title}
              {roadshow.startsAt && (
                <span className={styles.date}>
                  <Caption type="md">
                    Starts on {dayjs(roadshow.startsAt).format('D')}th{' '}
                    {dayjs(roadshow.startsAt).format('MMMM')}
                  </Caption>
                </span>
              )}
            </Heading>
            {pageContext.subtitle && (
              <ContentBox size="small">
                <FreeText type="lg" className={classNames(styles.subtitle)}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: pageContext.subtitle,
                    }}
                  />
                </FreeText>
              </ContentBox>
            )}
            <div className={styles.partner}>
              <img
                src="/images/roadshow/partner-tea-exchange.svg"
                alt="In partnership with: London Tea Exchange"
              />
            </div>
          </header>
        </Whitespace>
      </Container>
      <div className={classNames(styles.animation)} ref={ref}>
        <div className={classNames(styles.strip)}>
          <div
            className={classNames(styles.payload, {
              [styles.hasAnimation]: inView,
            })}
          >
            <img src="/images/roadshow/payload-1.png" alt="" />
            <img src="/images/roadshow/payload-1.png" alt="" />
            <img src="/images/roadshow/payload-1.png" alt="" />
            <img src="/images/roadshow/payload-1.png" alt="" />
            <img src="/images/roadshow/payload-1.png" alt="" />
          </div>
        </div>
        <div className={classNames(styles.strip, styles.stripWithShadow)}>
          <div className={styles.border}>
            <div
              className={classNames(styles.payload, {
                [styles.hasAnimation]: inView,
              })}
            >
              <img src="/images/roadshow/payload-2.png" alt="" />
              <img src="/images/roadshow/payload-2.png" alt="" />
              <img src="/images/roadshow/payload-2.png" alt="" />
              <img src="/images/roadshow/payload-2.png" alt="" />
              <img src="/images/roadshow/payload-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <Whitespace type="md">
        <Container className={styles.upcomingCities}>
          <Heading type="md">Upcoming Cities</Heading>
          <Whitespace type="md" hasAnimation={false}>
            <div className={styles.cities}>
              {map(
                city => (
                  <a
                    className={styles.city}
                    key={city.name}
                    href={getEventCalendar(city.date, city.name)}
                    onClick={() => onEventClick(city.name)}
                  >
                    <div className={styles.pin}>
                      <img src="/images/roadshow/pin.png" width={69} alt="" />
                    </div>
                    <Heading level={6} type="lg" className={styles.heading}>
                      {city.name}
                    </Heading>
                    <div className={styles.cal}>
                      <div className={styles.day}>
                        {dayjs(city.date).format('DD')}
                      </div>
                      <div className={styles.month}>
                        {dayjs(city.date).format('MMM')}
                      </div>
                    </div>
                  </a>
                ),
                cities || []
              )}
            </div>

            <FreeText
              type="lg"
              innerHTML={nl2br(roadshow.content)}
              className={styles.content}
            >
              {roadshow.content}
            </FreeText>
          </Whitespace>
        </Container>
      </Whitespace> */}
      <div id="roadshow-signup">
        <ZoneInlineEarlyAccessRoadshow
          __typename="StrapiGQL_ComponentZonesInlineEarlyAccessRoadshow"
          title="Early Access"
          id="0"
          location={location}
        />
      </div>
      <Container>
        <div className={styles.footnote}>
          <FreeText innerHTML={nl2br(roadshow.footnote)} type="sm">
            {nl2br(roadshow.footnote)}
          </FreeText>
        </div>
      </Container>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        ...StrapiGQL_PagesWithContentZones
      }
      roadshow(publicationState: LIVE) {
        id
        footnote
        content
        startsAt
        roadshow_cities {
          id
          name
          date
          visible
        }
      }
    }
  }
`
